/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-prototype-builtins */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { unescape } from 'he';
import window from 'window-or-global';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import get from 'lodash/get';
import curry from 'lodash/curry';
import reduce from 'lodash/reduce';
import flow from 'lodash/flow';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import pickBy from 'lodash/pickBy';
import classnames from 'classnames';
import reactCookie from 'react-cookie';
import axios from 'axios';
import { createSelector } from 'reselect';
import {
  getParams, buildBaseUrlWithPathname, replaceSpaceWithHyphenAndEncode, encodeAndReplaceEncodedSpaceWithPlus,
} from 'client-utils/utilities-router';
import { isMobile } from 'client-utils/utilities-page';
import { renderFormattedNumber } from 'client-utils/utilities-price';
import { removeBodyClass } from 'client-utils/bodyClass';
import { getProfileData } from 'client/common/components/YourNeimans/components/utilities';
import { showFeedbackFilter, showSearchFeedbackFilter } from 'vendor/opinionlab';
import { getAuthApiData } from 'client-utils/amplifyUtils';
import ConnectedLeftNav from 'storefront/components/LeftNavigation/leftNavigation';
import { RecentSizesStorageHandler, RecentDesignersHandler } from 'shared/Storage/RecentSizesStorageHandler';
import { getRequestParams } from 'client/profile/components/MyFavorites/MyFavorites'
import OmniFacet from 'plpFilters/components/OmniFacet/OmniFacet';
import OmniFreePickupFacet from 'plpFilters/components/OmniFacet/OmniFreePickupFacet';
import OmniFastDeliveryFacet from 'plpFilters/components/OmniFacet/OmniFastDeliveryFacet';
import {
  handleFilterSelection,
  handleFilterOptionSelection,
  handleClearAllFilters,
  handleURLUpdate,
  handleDesignerFilterChange,
  closeFacetedMobileFilter,
  handleSelectFilterByRouteParam,
  IN_STORE,
  fetchListOfStoreByAddress,
  toggleCategorySelector,
  SET_FAVORITE_DESIGNERS,
  SELECT_FAVORITE_DESIGNERS,
  FAST_DELIVERY,
  FREE_PICKUP,
  SUSTAINABILITY_TYPE,
  SUSTAINABLE_AND_ETHICAL
} from '../FacetedLeftNav/actions';
import GiftIcon from '../../../../../assets/images/gift/gift-icon.svg';
import { DESIGNER, GIFT_WRAP_KEY, SALE, ONSALE_SALE, REGULAR_SALE, SALE_PROMO_TEXT_DELIMITER } from '../../../../constants';
import './facets.scss';
import { useFilterOptions, formatFilterKey } from '../../../../../utilities/utilities-router';
import {
  ABTEST_GET_IT_FAST,
  ABTEST_PLP_SEO_REC
} from "../../../../../common/actions/actions-page"
import { isGlobalNavUpdateToggleEnabled } from 'cms/actions/index';
import { scrollToTop } from 'client-utils/scrollToTop';

export const hybridFacetsCategorySelectorKey = 'HybridFacetsCategorySelector';
let selectedFilterCheck = '';

export const getFavoriteDesigners = () => {
  return async (dispatch) => {

    const {
      fastlyHost, ucaId, headers,
    } = await getAuthApiData();

    try {
      const { data = {} } = await axios.get(
        `${fastlyHost}/uca-preferences/v1/profiles/${ucaId}/preferences`,
        { headers }
      );

      const {style: {brands = []} = {}} = data;

      dispatch({
        type: SET_FAVORITE_DESIGNERS,
        payload: brands,
      });

    } catch (error) {
      dispatch({
        type: SET_FAVORITE_DESIGNERS,
        payload: [],
      });
    }
  };
};

const updateSelectedFavoriteDesigner = (filterOptionKey) => {
  return async (dispatch) => {
    dispatch({
      type: SELECT_FAVORITE_DESIGNERS,
      payload: {
        selectedFilterOptionKeys: Array.isArray(filterOptionKey) ? filterOptionKey : [filterOptionKey]
      },
    });
  }
 }

function SignInModal(props) {
  const { isAuthenticated, hasFavorites } = props;
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const tooltipRef = useRef(null);
  const triggerRef = useRef(null);

  const handleKeyDown = (e, callback) => {
    if (e.key === 'Enter' || e.key === 'Return') {
      e.preventDefault();
      callback();
    }
  };

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      tooltipRef.current.style.visibility = 'hidden';
      setTooltipVisible(false);
    }
  };

  const handleFocus = () => {
      tooltipRef.current.style.visibility = 'visible';
      setTooltipVisible(true);
  };

  const handleClick = () => {
    if (window.YourNeimans) {
      window.YourNeimans.setData({
        isOpen: true,
        pageStack: ["accountAccessPage"]
      });
    }
  };

  if (isAuthenticated) {
    if (hasFavorites) {
      return (
        <div className={"favorite-designers__info"}
          tabIndex={0}
          role="button"
          onFocus={handleFocus}
          onKeyDown={(e) => handleKeyDown(e, handleFocus)}
          onBlur={(e) => handleBlur(e)}
          ref={triggerRef} 
        >
          i
          <div className="tooltip"
            ref={tooltipRef}
          >
            Need to update your Favorite <br />
            Designers? Go to your{" "}
            <span
              className="link"
              onClick={() => { window.location.href = '/my/preferences/stylepreferences' }}
              tabIndex={tooltipVisible ? 0 : -1}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === 'Return') {
                  e.preventDefault();
                  if (window?.location?.href) {
                    window.location.href = '/my/preferences/stylepreferences'
                  }
                }
              }}
            >
              Style <br />
              Preferences
            </span>{" "}
            to add or change <br /> your Designer List.
          </div>
        </div>
      );
    } else {
      return (
        <div className={"favorite-designers__info"}
          tabIndex={0}
          role="button"
          onFocus={handleFocus}
          onKeyDown={(e) => handleKeyDown(e, handleFocus)}
          onBlur={(e) => handleBlur(e)}
          ref={triggerRef} 
        >
          i
          <div className="tooltip"
            ref={tooltipRef}
          >
            No Favorite Designers? Go to
            <br /> your{" "}
            <span
              className="link"
              onClick={() => { window.location.href = '/my/preferences/stylepreferences' }}
              tabIndex={tooltipVisible ? 0 : -1}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === 'Return') {
                  e.preventDefault();
                  if (window?.location?.href) {
                    window.location.href = '/my/preferences/stylepreferences'
                  }
                }
              }}
            >
              Style Preferences
            </span>{" "}
            to add <br /> Designers and make your shopping experience easier.
          </div>
        </div>
      );
    }
  }

  return (
    <div 
      className={"favorite-designers__info"} 
      tabIndex={0} 
      role="button"
      onFocus={handleFocus}
      onKeyDown={(e) => handleKeyDown(e, handleFocus)}
      onBlur={(e) => handleBlur(e)}
      ref={triggerRef}
    >
      i
      <div className="tooltip"
        ref={tooltipRef}
      >
        Already have Favorite <br /> Designers?{" "}
        <span
          className="link"
          tabIndex={tooltipVisible ? 0 : -1}
          onKeyDown={(e) => handleKeyDown(e, handleClick)}
          onClick={handleClick}
        >
          Sign in
        </span>{" "}
        to see your <br /> Style Preferences.
      </div>
    </div>
  );
}


class Facets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSticky: false,
      selectedFilter: null,
      factedNavBottom: null,
      filterOptionsUpdated: false,
      omniFacetOpen: this.props.omniFacetOpen || this.props.isSysFacetOrderToggleOn,
      srpCategorySelected: false,
      isMultiSelect: false,
    };

    if (props.recentSizesStorageHandler) {
      this.recentSizesStorageHandler = props.recentSizesStorageHandler;
    } else {
      this.recentSizesStorageHandler = new RecentSizesStorageHandler();
    }

    if (props.RecentDesignersHandler) {
      this.RecentDesignersHandler = props.RecentDesignersHandler;
    } else {
      this.RecentDesignersHandler = new RecentDesignersHandler();
    }

    this.close = this.close.bind(this);
    this.handleFilterSelection = this.handleFilterSelection.bind(this);
    this.handleFilterOptionSelection = this.handleFilterOptionSelection.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.renderSelectedFilterStatus = this.renderSelectedFilterStatus.bind(this);
    this.renderFacetedFilterList = this.renderFacetedFilterList.bind(this);
    this.renderFacetedColorFilterList = this.renderFacetedColorFilterList.bind(this);
    this.renderRecentSize = this.renderRecentSize.bind(this);
    this.renderRecentDesigners = this.renderRecentDesigners.bind(this);
    this.displayFacetName = this.displayFacetName.bind(this);
    this.blockNavigation = this.blockNavigation.bind(this);
    this.getApplicableFilters = this.getApplicableFilters.bind(this);
    this.getUpdatedFilters = this.getUpdatedFilters.bind(this);
    this.getUpdatedGiftWrapItem = this.getUpdatedGiftWrapItem.bind(this);
    this.getUpdatedInStoreFilters = this.getUpdatedInStoreFilters.bind(this);
    this.getTotalSelectedFilterCount = this.getTotalSelectedFilterCount.bind(this);
    this.handleKeyDownClearAll = this.handleKeyDownClearAll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    const {
      facetDeafultExpandList,
      isDefaultFacetExpandOn,
      isMobilePhone,
      handleFilterSelection,
      fetchListOfStoreByAddress,
      locationInfo,
      brand,
      showFavoriteDesignersCheckBox,
      getFavoriteDesigners,
    } = this.props;

    const location = get(this.props.router, 'location', '');
    const storeNameFromParam = get(this.props.router, 'location.query.storeName', '');

    if (location !== '') {
      const { filterOptions } = getParams(location);
      if (filterOptions) {
        handleSelectFilterByRouteParam(filterOptions, storeNameFromParam);
      }
    }

    if (isDefaultFacetExpandOn && isMobilePhone === false) {
      const [categoryId = ''] = get(this.props, 'routeParams.categoryId', '').split('_');
      if (facetDeafultExpandList[categoryId]) {
        handleFilterSelection(facetDeafultExpandList[categoryId]);
      }
    }

    const zipCode = locationInfo && locationInfo.zip;
    const radius = 100;

    brand !== 'HC' && fetchListOfStoreByAddress(zipCode, radius);

    const { isAuthenticated } = getProfileData();

    if (showFavoriteDesignersCheckBox && isAuthenticated) {
      getFavoriteDesigners();
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedFiltersList, selectedFilterKey, isMobilePhone } = this.props;
    if (selectedFiltersList.length > 0) {
      const filteredList = selectedFiltersList.filter((item) => {
        return item.filterKey === 'Size';
      });
      const sizeFilters = filteredList.map((filter) => {
        return filter.selectedFilterOptionKey;
      });
      const previousSizeFilters = prevProps.selectedFiltersList.length > 0
        ? prevProps.selectedFiltersList.map((filter) => {
          return filter.selectedFilterOptionKey;
        }) : [];
      const newAppliedFilter = difference(sizeFilters, previousSizeFilters);

      const designerFilters = selectedFiltersList.filter((item) => {
        return item.filterKey === 'Designer';
      });

      const designerNames = designerFilters.map((filter) => {
        return filter.selectedFilterOptionKey;
      });

      const previousDesignerFilters = prevProps.selectedFiltersList.length > 0
        ? prevProps.selectedFiltersList.map((filter) => {
          return filter.selectedFilterOptionKey;
        }) : [];
      const newDesignerFilterAdded = difference(designerNames, previousDesignerFilters);

      if (newAppliedFilter.length > 0) {
        this.recentSizesStorageHandler.upsertRecentSizesForFilter(newAppliedFilter);
        this.RecentDesignersHandler.upsertRecentDesigners(newDesignerFilterAdded);
      }
    }

    const location = get(this.props.router, 'location', '');
    const { filterOptions } = getParams(location);
    const { srpCategorySelected } = this.state;
    if (filterOptions) {
      let filterOptionObject = {};
      try {
        filterOptionObject = JSON.parse(filterOptions);
      } catch (e) {
        filterOptionObject = {};
      }
      if (filterOptionObject.hasOwnProperty('Category') && srpCategorySelected === false) {
        this.setState({ srpCategorySelected: true });
      }
    }

    if(isMobilePhone && selectedFilterKey){
      const selectedFilterHeader = document.getElementsByClassName('faceted-left-nav__filter__title__selected')[0];
      if (typeof selectedFilterHeader !== 'undefined') {
        selectedFilterHeader.scrollIntoView(true);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (!isMobile()) {
      const navElement = document.getElementById('faceted-left-nav');
      const facetedLeftNav = navElement.getBoundingClientRect();
      const spacerElement = document.getElementById('product-list__facets-spacer');
      const fixedNavElement = document.getElementById('faceted-left-nav__child');

      const footerContentContainer = document.getElementsByClassName('footer-content-container')[0];
      let commonBrContainer = document.getElementsByClassName("common-br-container")[0];

      if (this.props.recentlyViewedToggle || this.props.recentlyViewedAbTest) {
        commonBrContainer = document.getElementsByClassName("recently-viewed-products")[0];
      }
      else if (this.props.relatedProductsToggle && !isEmpty(this.props.graphiteHQRelatedProducts) && !this.props.seoProductRecCarouselAbTest) {
        commonBrContainer = document.getElementsByClassName("related-products")[0];
      }
      else if (this.props.relatedLinksToggle || (this.props.relatedFoundItLinksToggle && !isEmpty(this.props.foundItCategoryLinks) && !isEmpty(this.props.foundItCategoryLinks['Main Links']) && this.props.foundItCategoryLinks['Main Links'].length > 0)) {
        commonBrContainer = document.getElementsByClassName("related-categories")[0];
      }

      const footerContainer = commonBrContainer || footerContentContainer;

      if (facetedLeftNav.top < 1) {
        if (!this.state.isSticky) {
          this.setState({ isSticky: true });
          fixedNavElement.style.width = `${spacerElement.getBoundingClientRect().width}px`;
          if (spacerElement && fixedNavElement) {
            spacerElement.style.height = `${fixedNavElement.getBoundingClientRect().height - spacerElement.getBoundingClientRect().top}px`;
            navElement.style.marginTop = '0px';
          }
        }
        this.setState({ factedNavBottom: null });
        if (footerContainer.getBoundingClientRect().top
          <= fixedNavElement.getBoundingClientRect().bottom) {
            this.setState({ factedNavBottom: (window.innerHeight - footerContainer.getBoundingClientRect().top) });
        } else {
          this.setState({ factedNavBottom: null });
        }
      } else if (this.state.isSticky) {
        this.setState({ isSticky: false });
        fixedNavElement.style.width = null;
        spacerElement.style.height = '0px';
        if (!this.props.newVisNavToggle && this.props.pageId !== 'PAGE_ID_SRP') {
          navElement.style.marginTop = '49px';
        }
      }
    }
  }

  close(e) {
    removeBodyClass('pane-open');
    this.props.closeFacetedMobileFilter();
    this.props.toggleCategorySelector();
    const { selectedFilterKey, facetedFiltersList, filterDescriptions } = this.props;
    const { isMultiSelect } = this.state;

    if (isMobile()) {
      const facetsMenuHeader = document.getElementsByClassName('faceted-left-nav__child__mobile')[0];

      if (typeof facetsMenuHeader !== 'undefined') {
        facetsMenuHeader.scrollTo(0, 0);
      }
      const selectedValues = facetedFiltersList[selectedFilterKey];
      const selectedKeys = !isEmpty(selectedValues) ? Object.keys(selectedValues) : [];
      const filteredKeys = selectedKeys.filter((e) => {
        return selectedValues[e] === true ? e : null;
      });
      filteredKeys.includes('csp') && reactCookie.save(`cCspTogglePath`, 'facet', { path: '/' });
      const firstValue = filteredKeys[0];
      if (this.state.filterOptionsUpdated) {
        this.props.handleURLUpdate(
          this.props.router,
          false,
          selectedFilterKey,
          firstValue,
          isMultiSelect,
          filterDescriptions,
        );
        scrollToTop(e);
        this.setState({ filterOptionsUpdated: false });
      }
    }
  }

  handleFilterSelection(filterKey, filterIndex) {
    this.props.handleDesignerFilterChange('');
    const { omniFacetOpen, isMultiSelect } = this.state;
    const { filterDescriptions } = this.props;

    if (omniFacetOpen && !isMobile()) {
      this.setState({ omniFacetOpen: false });
      if (filterKey === IN_STORE || filterKey === FREE_PICKUP || filterKey === FAST_DELIVERY) {
        return;
      }
    }

    this.props.handleFilterSelection(filterKey, filterIndex);

    if (isMobile()) {
      const isCollapseAction = this.state.selectedFilter === filterKey;
      const countElement = document.getElementsByClassName('faceted-left-nav__items-count')[0];
      const { selectedFilterKey, facetedFiltersList } = this.props;

      if (typeof countElement !== 'undefined') {
        countElement.scrollIntoView(true);
      }

      if (this.state.filterOptionsUpdated) {
        const selectedValues = facetedFiltersList[selectedFilterKey];
        const selectedKeys = !isEmpty(selectedValues) ? Object.keys(selectedValues) : [];
        const filteredKeys = selectedKeys.filter((e) => {
          return selectedValues[e] === true ? e : null;
        });
        const firstValue = filteredKeys[0];
        this.props.handleURLUpdate(this.props.router, false, filterKey, firstValue, isMultiSelect, filterDescriptions);
        this.setState({ filterOptionsUpdated: false });
      }

      this.setState({ selectedFilter: isCollapseAction ? null : filterKey }, () => {
        const selectedFilterHeader = document.getElementsByClassName('faceted-left-nav__filter__title__selected')[0];

        if (typeof selectedFilterHeader !== 'undefined') {
          selectedFilterHeader.scrollIntoView(true);
        }
      });
    }
  }

  handleFilterOptionSelection(selectedFilterKey, filterOptionKey, e, isFavorite = null, selectedStore = null) {
    let favoriteDesignerChecked = false;
    if (e !== undefined) { e.stopPropagation(); }
    if (selectedFilterKey.indexOf('Size') !== -1
      && e.currentTarget.type !== 'submit'
    ) {
      let elementIsChecked = false;
      if (e && e.target.type === 'checkbox') {
        elementIsChecked = e.target.checked;
      } else {
        elementIsChecked = !e.currentTarget.querySelector('input').checked;
      }
      if ((
        (selectedFilterKey.indexOf('Size') !== -1)
        || (selectedFilterKey.toLowerCase().indexOf('volume') !== -1))
        && elementIsChecked
      ) {
        this.recentSizesStorageHandler.upsertRecentSizesForFilter([filterOptionKey]);
      }
    }

    if (selectedFilterKey.indexOf('Designer') !== -1
      && e.currentTarget.type !== 'submit'
    ) {
      let elementIsChecked = false;
      if (e && e.target.type === 'checkbox') {
        elementIsChecked = e.target.checked;
        if(e && (e.key === 'Enter' || e.key === 'Return' || e.keyCode === 13)) {
          e.preventDefault();
          elementIsChecked = !e.target.checked ? true : false;
        }
      } else {
        elementIsChecked = !e.currentTarget.querySelector('input').checked;
      }
      if ((
        (selectedFilterKey.indexOf('Designer') !== -1)
      )
        && elementIsChecked
      ) {

        if (this.props.showFavoriteDesignersCheckBox && Array.isArray(filterOptionKey)) {
          filterOptionKey.map(item => this.RecentDesignersHandler.upsertRecentDesigners([item]));
        } else {
          this.RecentDesignersHandler.upsertRecentDesigners([filterOptionKey]);
        }

        if(isFavorite !== null) {
          favoriteDesignerChecked = true;
        }
      }
      else {
        if(isFavorite !== null || this.props.favoriteDesigners.includes(filterOptionKey)) {
          this.props.updateSelectedFavoriteDesigner([]);
        }
      }
    }

    let isMultiSelect = this.state.isMultiSelect;
    if (isMobile()) {
      const { facetedFiltersList } = this.props;
      const selectedList = facetedFiltersList[selectedFilterKey === FREE_PICKUP || selectedFilterKey === FAST_DELIVERY ? IN_STORE : selectedFilterKey];
      const listKeys = Object.keys(selectedList);
      const selectedValues = listKeys.filter((element) => selectedList[element] === true);
      const valuesLength = selectedValues.length;

      if (valuesLength >= 1) {
        isMultiSelect = true;
      } else if (valuesLength === 0) {
        isMultiSelect = false;
      }
    }
    selectedFilterCheck = filterOptionKey;

    if(favoriteDesignerChecked) {
      const clonedFilterKey = [];
      const {Designer} = this.props.facetedFiltersList;
      filterOptionKey.forEach(key => {
        if(Designer[key] === false){
          clonedFilterKey.push(key);
        }
      })

      this.props.updateSelectedFavoriteDesigner(filterOptionKey);
      this.props.handleFilterOptionSelection(selectedFilterKey, clonedFilterKey, this.props.pageId, false, selectedStore);
    }
    else {
      this.props.handleFilterOptionSelection(selectedFilterKey, filterOptionKey, this.props.pageId, false, selectedStore);
    }
    this.setState({ filterOptionsUpdated: true, isMultiSelect });

    if (!isMobile() && e) {
      this.props.handleURLUpdate(
        this.props.router,
        false,
        selectedFilterKey,
        filterOptionKey,
        false,
        this.props.filterDescriptions
      );
      scrollToTop(e);
      this.setState({ filterOptionsUpdated: false });
    }
  }

  scrollToHeader() {
    const productListHeaderTitle = document.getElementsByClassName('product-list__header__title')[0];
    const productListHeaderBottom = document.getElementsByClassName('product-list__header__bottom')[0];
    const productListScrollTo = productListHeaderTitle || productListHeaderBottom;

    if (typeof productListScrollTo !== 'undefined') {
      productListScrollTo.scrollIntoView(true);
    }
  }

  clearAll(e) {
    this.props.handleClearAllFilters();
    this.props.handleURLUpdate(this.props.router, true);
    this.setState({ srpCategorySelected: false, isMultiSelect: false });
    scrollToTop(e);
  }

  handleKeyDownClearAll(e) {
    if (e.key === "Enter" || e.key === "Return") {
      e.stopPropagation();
      this.clearAll(e);
    }
  }

  displayFacetName(displayText) {
    const { omniFacetName } = this.props;
    if (displayText === SUSTAINABILITY_TYPE) {
      displayText = SUSTAINABLE_AND_ETHICAL;
    }
    if (displayText !== IN_STORE) {
      return displayText;
    }
    return omniFacetName;
  }

  blockNavigation(e) {
    e.preventDefault();
  }

  renderFacetedColorFilterList(validFacets, facets, filterKey, facetedFiltersList) {
    const baseUrl = buildBaseUrlWithPathname(this.props.router);
    const noResult = ((this.props.noResultsFound !== '') ? 0 : 25);
    const { seoToggle } = this.props;
    return (
      <ul
        className={classnames('faceted-left-nav__facet', { color_ul: validFacets.length > 7 }, { 'full-row': true })}
        style={{ marginBottom: noResult, paddingTop: '2px' }}
      >
        {validFacets.map((key) => {
          const label = this.renderMatchingDesignerListResultCharacters(unescape(key));
          return (
            <li
              key={`${key}`}
              onClick={(e) => {
                this.handleFilterOptionSelection(filterKey, key, e);
              }}
            >
              <span
                className="faceted-left-nav__facet__desc"
              >
                <div className={`faceted-left-nav__facet__color${facetedFiltersList[key] ? ' selected' : ''} ${key}`}>
                  <img
                    alt={key}
                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                  />
                </div>
                {
                  seoToggle
                    ? (
                      <a
                        href={`${baseUrl}?color=${encodeAndReplaceEncodedSpaceWithPlus(key)}`}
                        className={classnames(
                          'faceted-left-nav__facet__label',
                          { 'filter--selected': facetedFiltersList[key] }
                        )}
                        dangerouslySetInnerHTML={{ __html: label }}
                        aria-label={`color: ${key}. ${facetedFiltersList[key] ? 'selected' : 'not selected'}`}
                        onClick={this.blockNavigation}
                      />
                    )
                    : (
                      <span
                        className={classnames(
                          'faceted-left-nav__facet__label',
                          { 'filter--selected': facetedFiltersList[key] }
                        )}
                        dangerouslySetInnerHTML={{ __html: label }}
                      />
                    )
                }
              </span>

            </li>
          );
        })
        }
      </ul>
    );
  }

  renderRecentSize(sizes, isFilterDisabled = false) {
    const { facetedFiltersList, seoToggle } = this.props;
    if (!isEmpty(facetedFiltersList) && isEmpty(facetedFiltersList.Size)) {
      Object.keys(sizes).forEach((key) => {
        if (sizes[key].length > 0) {
          sizes[key].forEach((size) => {
            if (size.sizes.length > 0) {
              size.sizes.forEach((item) => {
                facetedFiltersList.Size[item] = false;
              });
            }
          });
        }
      });
    }
    const needEndLine = sizes && Object.keys(sizes).length === 1;
    const recentSizesArray = this.recentSizesStorageHandler.getRecentSizesForFilter();
    const SizeFilterKey = 'Size';
    const baseUrl = buildBaseUrlWithPathname(this.props.router);

    if (recentSizesArray) {
      const sizeNames = Object.keys(facetedFiltersList.Size);
      const restrictedRecentSizes = recentSizesArray.filter((recentSizeName) => {
        return sizeNames.indexOf(recentSizeName) !== -1;
      });

      if (restrictedRecentSizes.length > 0) {
        return (
          <Fragment>
            <div className="grid-100 facets-recent-sizes" style={{ display: isFilterDisabled ? 'none' : 'block' }}>
              <div id="recent-sizes-title" className="recent-sizes-title" tabIndex={0}>Recent Sizes</div>
              <div role="group" aria-labelledby="recent-sizes-title" className={classnames('recent-sizes-options-group',
                { 'required-padding': !needEndLine })}
              >
                {
                  restrictedRecentSizes.splice(0, 4).map((value) => (
                    <div
                      className="recent-size-frame"
                      key={value}
                      onClick={(e) => {
                        this.handleFilterOptionSelection(SizeFilterKey, value, e);
                      }}
                      value={value}
                    >
                      <input
                        type="checkbox"
                        className="recent-sizes-input"
                        aria-label={value}
                        checked={facetedFiltersList.Size[value]}
                        onClick={(e) => {
                          this.handleFilterOptionSelection(SizeFilterKey, value, e);
                        }}
                        key={value}
                        value={value}
                        id={value}
                      />
                      <span className="recent-size-tickmark" />
                      {
                        seoToggle
                          ? (
                            <a
                              href={`${baseUrl}?size=${encodeAndReplaceEncodedSpaceWithPlus(value)}`}
                              className={classnames(
                                { 'selected-option': facetedFiltersList.Size[value] }
                              )}
                              onClick={this.blockNavigation}
                            >
                              <label for={value} className={"recent-sizes-group-label"} >{ value }</label>
                            </a>
                          )
                          : (
                            <label
                              className={classnames(
                                'recent-sizes-group-label',
                                { 'selected-option': facetedFiltersList.Size[value] }
                              )}
                              for={value}
                            >
                              { value }
                            </label>
                          )
                      }
                    </div>
                  ))
                }
              </div>
            </div>
            <hr className="end-line" />
          </Fragment>
        );
      }
      return null;
    }
    return null;
  }

  renderRecentDesigners(designers, isFilterDisabled = false) {
    const { facetedFiltersList, seoToggle, selectedFavoriteDesigners } = this.props;

    if (!isEmpty(facetedFiltersList) && isEmpty(facetedFiltersList.Designer)) {
      Object.keys(designers).forEach((key) => {
        if (designers[key].length > 0) {
          designers[key].forEach((item) => {
            if (item.sizes.length > 0) {
              item.sizes.forEach((item) => {
                facetedFiltersList.Size[item] = false;
              });
            }
          });
        }
      });
    }

    const needEndLine = designers && Object.keys(designers).length === 1;
    const recentDesignersArray = this.RecentDesignersHandler.getRecentDesigners();
    const SizeFilterKey = 'Designer';
    const baseUrl = buildBaseUrlWithPathname(this.props.router);

    if (recentDesignersArray) {
      const designerNames = Object.keys(facetedFiltersList.Designer);
      const restrictedRecentDesigners = recentDesignersArray.filter((recentSizeName) => {
        return designerNames.indexOf(recentSizeName) !== -1;
      });

      if (restrictedRecentDesigners.length > 0) {
        return (
          <Fragment>
            <div className="grid-100 facets-recent-designers" style={{ display: isFilterDisabled ? 'none' : 'block' }}>
              <div className="recent-designers-title">Recent Designers</div>
              <div className={classnames('recent-sizes-options-group',
                { 'required-padding': !needEndLine })}
              >
                {
                  restrictedRecentDesigners.splice(0, 4).map((value) => (
                    <div
                      className="recent-size-frame"
                      key={value}
                      onClick={(e) => {
                        this.handleFilterOptionSelection(SizeFilterKey, value, e);
                      }}
                      value={value}
                    >
                      <input
                        type="checkbox"
                        className="recent-sizes-input"
                        aria-label={value}
                        checked={facetedFiltersList.Designer[value]}
                        onClick={(e) => {
                          this.handleFilterOptionSelection(SizeFilterKey, value, e);
                        }}
                        key={value}
                        value={value}
                      />
                      <span className="recent-size-tickmark" />
                      {
                        seoToggle
                          ? (
                            <a
                              href={`${baseUrl}?designer=${encodeAndReplaceEncodedSpaceWithPlus(value)}`}
                              className={classnames(
                                { 'selected-option': facetedFiltersList.Designer[value] }
                              )}
                              tabindex="-1"
                              dangerouslySetInnerHTML={{ __html: value }}
                              onClick={this.blockNavigation}
                            >
                            </a>
                          )
                          : (
                            <label
                              className={classnames(
                                { 'selected-option': facetedFiltersList.Designer[value] }
                              )}
                              dangerouslySetInnerHTML={{ __html: value }}
                            >
                            </label>
                          )
                      }
                    </div>
                  ))
                }
              </div>
            </div>
            <hr className="end-line" />
          </Fragment>
        );
      }
      return null;
    }
    return null;
  }

  renderFacetedFilterList(
    facets,
    filterKey,
    facetsOrderList,
    facetsGroupOrder = false,
    facetedFiltersList,
    isFilterDisabled,
    isPageSRP,
    filterDescriptions,
  ) {
    let validFacets;

    if (filterKey === IN_STORE || filterKey === FREE_PICKUP || filterKey === FAST_DELIVERY) {
      validFacets = Object.keys(pickBy(facets, (item) => item > 0));
      if (isPageSRP) {
        validFacets = ['sdd', 'ndd', 'csp'];
      }
    } else {
      validFacets = filter(facetsOrderList, (facetKey) => !isNil(facets[facetKey]));
    }
    const baseUrl = buildBaseUrlWithPathname(this.props.router);

    if (filterKey === IN_STORE) {
      return (
        <OmniFacet
          filterKey={filterKey}
          validFacets={validFacets}
          facetedFiltersList={facetedFiltersList}
          submitFunc={this.handleFilterOptionSelection}
          baseUrl={baseUrl}
        />
      );
    }
    if ( this.props.getItFastSplitFacetToggle && filterKey === FREE_PICKUP ) {
      return (
        <OmniFreePickupFacet
          filterKey={filterKey}
          validFacets={validFacets}
          facetedFiltersList={facetedFiltersList}
          submitFunc={this.handleFilterOptionSelection}
          baseUrl={baseUrl}
        />
      );
    }
    if ( this.props.getItFastSplitFacetToggle && filterKey === FAST_DELIVERY ) {
      return (
        <OmniFastDeliveryFacet
          filterKey={filterKey}
          validFacets={validFacets}
          facetedFiltersList={facetedFiltersList}
          submitFunc={this.handleFilterOptionSelection}
          baseUrl={baseUrl}
        />
      );
    }

    const noResult = ((this.props.noResultsFound !== '') ? 0 : 25);
    const { internationalPriceFacet, seoToggle, selectedFavoriteDesigners, showFavoriteDesignersCheckBox } = this.props;

    const { isAuthenticated } = getProfileData();
    const applicableFavoriteDesigners = intersection(this.props.favoriteDesigners, validFacets);

    return (
      <div>
        { (filterKey === 'Designer') && this.renderDesignerFreeFormText() }
        {(filterKey === 'Color')
          ? this.renderFacetedColorFilterList(validFacets, facets, filterKey, facetedFiltersList)
          : (
            <Fragment>
              {filterKey === 'Size' ? this.renderRecentSize(facetsGroupOrder, isFilterDisabled) : null}

              {
                showFavoriteDesignersCheckBox && filterKey === DESIGNER && (
                  <>
                    <div className="plp-favorite-designers recent-sizes-options-group">
                      <div className="recent-size-frame">
                        <input
                          type="checkbox"
                          role="checkbox"
                          className="recent-sizes-input"
                          aria-label={"Favorite Designers"}
                          id="favorite-designers"
                          checked={selectedFavoriteDesigners}
                          onClick={(e) => {
                            this.handleFilterOptionSelection('Designer', applicableFavoriteDesigners, e, !selectedFavoriteDesigners);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === "Return") {
                              e.preventDefault();
                              this.handleFilterOptionSelection('Designer', applicableFavoriteDesigners, e, !selectedFavoriteDesigners);
                            }
                          }}
                          disabled={isAuthenticated ? false : true}
                          key={"Favorite Designers"}
                          value={"Favorite Designers"}
                        />
                        <span className="recent-size-tickmark" />

                        <label
                          className={classnames({
                            "selected-option": selectedFavoriteDesigners
                          })}
                          htmlFor="favorite-designers"
                          tabIndex={isAuthenticated ? -1 : 0}
                        >
                          Favorite Designers{" "}
                          {isAuthenticated && <span>({applicableFavoriteDesigners.length})</span>}
                        </label>

                        {
                          <SignInModal
                            isAuthenticated={isAuthenticated}
                            hasFavorites={applicableFavoriteDesigners.length > 0}
                          />
                        }
                      </div>
                    </div>
                    <hr class="end-line"></hr>
                  </>
                )
              }

              {this.props.hpGenderToggle && filterKey === 'Designer' ? this.renderRecentDesigners(facetsGroupOrder, isFilterDisabled) : null}
              <ul
                className={classnames('faceted-left-nav__facet', { 'fixed-height': filterKey === 'Designer' }, { 'full-row': true })}
                style={{ marginBottom: noResult }}
              >
                {validFacets.map((key, index) => {
                  let label = this.renderMatchingDesignerListResultCharacters(unescape(key));
                  if (internationalPriceFacet) {
                    if (filterKey === 'priceBandUpper' || filterKey === 'priceBandLower' || filterKey === 'priceBandDefault') {
                      label = internationalPriceFacet.facetsOrderList[index];
                    }
                  }
                  if (filterKey === GIFT_WRAP_KEY) {
                    label = this.getGiftwrapLabel(key);
                  }
                  if(filterKey === SALE){
                    label = this.getSaleLable(key);
                  }
                  return (
                    <li
                      key={`${key}`}
                      onClick={(e) => {
                        this.handleFilterOptionSelection(filterKey, key, e);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === 'Return') {
                          e.preventDefault();
                          this.handleFilterOptionSelection(filterKey, key, e);
                        }
                      }}
                    >
                      <span className="faceted-left-nav__facet__input">
                        <input
                          type="checkbox"
                          checked={facetedFiltersList[key]}
                          value={key}
                          data-tid={`facet-input-key-${key}`}
                          aria-label={`${key}${!(filterKey === 'Color' || filterKey === 'Size') ?  ', '+ facets[key]+ ' items' : '' }`}
                        />
                      </span>
                      <span className="faceted-left-nav__facet__desc">
                        {filterKey === 'Color'
                    && (
                      <div className={`faceted-left-nav__facet__color ${key}`}>
                        <img
                          aria-label={key}
                          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                        />
                      </div>
                    )}
                        { seoToggle
                          ? (
                            <a
                              href={`${baseUrl}?${replaceSpaceWithHyphenAndEncode((filterDescriptions[filterKey]).toLowerCase())}=${encodeAndReplaceEncodedSpaceWithPlus(key)}`}
                              className={classnames(
                                'faceted-left-nav__facet__label',
                                { 'filter--selected': facetedFiltersList[key] },
                              )}
                              tabindex="-1"
                              dangerouslySetInnerHTML={{ __html: label }}
                              onClick={this.blockNavigation}
                            />
                          )
                          : (
                            <span
                              className={classnames(
                                'faceted-left-nav__facet__label',
                                { 'filter--selected': facetedFiltersList[key] },
                              )}
                              dangerouslySetInnerHTML={{ __html: label }}
                            />
                          )
                      }
                        {!(filterKey === 'Color' || filterKey === 'Size')
                    && <span className="faceted-left-nav__facet__count">{`(${facets[key]})`}</span>}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </Fragment>
          )
        }
      </div>
    );
  }

  renderMatchingDesignerListResultCharacters(label) {
    const term = this.props.designerSearchText;

    const escapeSplChars = (input) => input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    if (this.props.designerSearchText !== '') {
      const termMatch = label.match(new RegExp(escapeSplChars(term), 'ig'));
      if (termMatch) {
        return label.replace(termMatch[0], `<span>${termMatch[0]}</span>`);
      }
    }
    return label;
  }

  getSeletedFilterLabel(filter, isDomestic, labelsMap, internationalDomesticMap) {
    if (!isDomestic &&
       (filter.filterKey === 'priceBandUpper' ||
       filter.filterKey === 'priceBandLower' ||
       filter.filterKey === 'priceBandDefault' || 
       filter.filterKey === 'internationalPrice') &&
       !isEmpty(internationalDomesticMap && internationalDomesticMap[filter.selectedFilterOptionKey])) {
      return internationalDomesticMap[filter.selectedFilterOptionKey];
    } if (filter.filterKey === GIFT_WRAP_KEY) {
      return this.getGiftwrapLabel(filter.selectedFilterOptionKey);
    }

    if (filter.filterKey === SALE){
      return this.getSaleLable(filter.selectedFilterOptionKey)
    }
    return (labelsMap[filter.selectedFilterOptionKey] || filter.selectedFilterOptionKey);
  }

  getSeletedFilterName(filterDescriptions, filter) {
    const {filterKey, selectedFilterOptionKey} = filter;
    if (filterKey === SUSTAINABILITY_TYPE) {
      return SUSTAINABLE_AND_ETHICAL;
    }
    if (this.props.getItFastSplitFacetToggle && filterKey === IN_STORE) {
      if (selectedFilterOptionKey === "sdd" || selectedFilterOptionKey === "ndd") {
        return FAST_DELIVERY;
      }
      else if (selectedFilterOptionKey === "csp") {
        return FREE_PICKUP;
      }
      return filterDescriptions[filterKey];
    }
    return filterDescriptions[filterKey];
  }

  removeDuplicateIntlPriceFilter = (selectedFilters) => {
    const selectedPriceFilters = selectedFilters.filter((filter) => { return (filter.filterKey === "priceBandUpper" || filter.filterKey === "priceBandLower" || filter.filterKey === "priceBandDefault")});

    return selectedFilters.filter((filter) => {
      return !(selectedPriceFilters.some((priceFilter) =>
      priceFilter.selectedFilterOptionKey === filter.selectedFilterOptionKey && filter.filterKey === 'internationalPrice'))
    });
  }

  renderSelectedFiltersList(selectedFiltersList, filterDescriptions) {
    const labelsMap = {
      sdd: 'Today',
      ndd: 'Tomorrow',
      csp: this.props.getItFastSplitFacetToggle ? "Today" : 'Store Pickup',
    };

    const removeNonValidOmniFacetOptions = (item) => {
      if (item.filterKey === IN_STORE || item.filterKey === FREE_PICKUP || item.filterKey === FAST_DELIVERY) {
        if (
          item.selectedFilterOptionKey === 'sdd'
          || item.selectedFilterOptionKey === 'ndd'
          || item.selectedFilterOptionKey === 'csp'
        ) {
          return item;
        }
        return false;
      }

      if (item.filterKey === 'Category') {
        return false;
      }

      return item;
    };

    let selectedFilters = selectedFiltersList.filter(removeNonValidOmniFacetOptions);
    selectedFilters = selectedFiltersList.filter(x => x.selectedFilterOptionKey !== "R+Co Bleu" && x.selectedFilterOptionKey !== "R Co Bleu")

    if (selectedFilters.length === 0) return null;

    const { applicableFilters, isDomestic } = this.props;
    let internationalDomesticMap;
    if (!isDomestic) {
      const internationalPriceIndex = findIndex(applicableFilters, { filterKey: 'internationalPrice' }, {});
      const domesticPriceIndex = applicableFilters
        .findIndex(({ filterKey }) => filterKey === 'priceBandUpper' || filterKey === 'priceBandLower' || filterKey === 'priceBandDefault');
      if (internationalPriceIndex !== -1 && domesticPriceIndex !== -1) {
        const internationalPrices = applicableFilters[internationalPriceIndex]?.facetsOrderList;
        const domesticPrices = applicableFilters[domesticPriceIndex]?.facetsOrderList;
        internationalDomesticMap = (internationalPrices && domesticPrices) && internationalPrices.reduce((result, field, index) => {
          result[domesticPrices[index]] = field;
          return result;
        }, {});
      }

      selectedFilters = this.removeDuplicateIntlPriceFilter(selectedFilters);
    }

    return (
      <div className="faceted-left-nav__filters__selected_filters">
        <header>
          <h3 tabIndex="0" className="faceted-left-nav__filters__selected_filters__title">Selected Filters</h3>
          <div className="faceted-left-nav__filters__selected_filters__clearall">
            <span
              aria-label="Select to clear all filters"
              role="button"
              tabIndex="0"
              onClick={(e) => { this.clearAll(e); }}
              onKeyDown={(e) => { this.handleKeyDownClearAll(e); }}
            >
              Clear all
            </span>
          </div>
        </header>

        <ul className="faceted-left-nav__filters__selected_filters__list">
          {
            selectedFilters.map((filter, index) => (
              <li key={`Selected_FiltersList_${index}`}>
                <div className="faceted-left-nav__filters__selected_filters__list__desc">
                  <span dangerouslySetInnerHTML={{ __html: `${this.getSeletedFilterName(filterDescriptions, filter)}: ${this.getSeletedFilterLabel(filter, isDomestic, labelsMap, internationalDomesticMap)}` }}
                    tabIndex="0"
                    aria-label={`Selected filter, ${this.getSeletedFilterName(filterDescriptions, filter)}, ${this.getSeletedFilterLabel(filter, isDomestic, labelsMap, internationalDomesticMap)}`}
                  >
                  </span>
                  <button
                    aria-label="Select to remove this filter"
                    className="faceted-left-nav__filters__selected_filters__list__remove"
                    onClick={(e) => {
                      this.handleFilterOptionSelection(filter.filterKey,
                        filter.selectedFilterOptionKey, e);
                    }}
                  >
                    <img
                      alt=""
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" />
                  </button>
                </div>
              </li>
            ))
          }
        </ul>
      </div>
    );
  }

  renderDesignerFreeFormText() {
    const noResultsFound = this.props.noResultsFound;
    return (
      <div className="faceted-left-nav__designer_filter">
        <input
          type="text"
          name="search"
          placeholder="Search Designers"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          aria-label="designer search box"
          value={this.props.designerSearchText}
          onChange={(e) => {
            this.props.handleDesignerFilterChange(e.target.value);
          }}
        />
        {
          (noResultsFound !== '')
          && (
          <div className="faceted-left-nav__designer_filter__noresults">
            { this.props.noResultsFound }
          </div>
          )
        }
      </div>
    );
  }

  renderSelectedFilterStatus(isSelected, isDiabled, displayText="") {
    if (isDiabled) return null;
    const selectedFilterStatus = (statusClassName, status) => (
      <span className={statusClassName} title={`${status} ${displayText}`}>
        <img alt={`${status} ${displayText}`} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" />
      </span>
    );
    if (isSelected) {
      return selectedFilterStatus('faceted-left-nav__filter__status_expanded','Collapse');
    }
    return selectedFilterStatus('faceted-left-nav__filter__status_collapsed','Expand');
  }

  isIncircleMember() {
    try {
      const incircleData = window?.sessionStorage?.getItem('incircleData');
      const incircleStatusLevel = !isEmpty(incircleData)
        // eslint-disable-next-line
        && JSON.parse(incircleData)?.[0]?.benefit_level;
      const isIncircleMember = +incircleStatusLevel >= 3;

      return isIncircleMember;
    } catch (e) {
      console.error(`Error occurred while getting incircle data. Error: ${e}`);
    }
  }

  getUpdatedGiftWrapItem() {
    const { applicableFilters, giftWrapFilterText: { freeIncircleGiftPackaging, eligibleForGiftPackaging } } = this.props;
    const isIncircleMember = this.isIncircleMember();
    const displayText = isIncircleMember ? freeIncircleGiftPackaging?.title : eligibleForGiftPackaging?.title;
    const giftWrapItem = applicableFilters.find(({ filterKey }) => filterKey === GIFT_WRAP_KEY);

    return {
      ...giftWrapItem,
      displayText,
    };
  }

  getGiftwrapLabel(key) {
    const { giftWrapFilterText: { freeIncircleGiftPackaging, eligibleForGiftPackaging } } = this.props;

    if (key === '1') {
      const isIncircleMember = this.isIncircleMember();
      return isIncircleMember ? freeIncircleGiftPackaging?.facet : eligibleForGiftPackaging?.facet;
    }
    return key;
  }

  getSaleLable(key) {
    if (key && key !== (REGULAR_SALE || ONSALE_SALE)) {
      let promotionText =  key?.split(SALE_PROMO_TEXT_DELIMITER)
      return promotionText?.length > 1 ? promotionText[1] : promotionText;
    }
    return key;
  }

  getUpdatedFilters() {
    const {
      applicableFilters, holidayGiftFilter, holidayGiftFilterBtm, pageId,
    } = this.props;
    const updatedGiftWrapItem = this.getUpdatedGiftWrapItem();
    const giftwrapFacetIdx = applicableFilters.findIndex(({ filterKey }) => filterKey === GIFT_WRAP_KEY);
    const filters = applicableFilters.filter(({ filterKey }) => filterKey !== GIFT_WRAP_KEY);
    if (holidayGiftFilter && pageId === 'PAGE_ID_PLP' && giftwrapFacetIdx !== -1) {
      if (holidayGiftFilterBtm) {
        filters.push(updatedGiftWrapItem);
      } else if (filters[0].filterKey === 'In Store') {
        filters.splice(1, 0, updatedGiftWrapItem);
      } else {
        filters.splice(0, 0, updatedGiftWrapItem);
      }
    }
    return filters;
  }

  getUpdatedInStoreFilters(inStoreIndex) {
    const {
      applicableFilters, getItFastSplitFacetToggle, pageId
    } = this.props;
    const filters = [...applicableFilters];
    const freePickupFacet = { ...filters[inStoreIndex], displayText: FREE_PICKUP, filterKey: FREE_PICKUP};
    const fastDeliveryFacet = { ...filters[inStoreIndex], displayText: FAST_DELIVERY, filterKey: FAST_DELIVERY};
    if (getItFastSplitFacetToggle && pageId === 'PAGE_ID_PLP' && inStoreIndex !== -1) {
      filters.splice(inStoreIndex, 1, freePickupFacet, fastDeliveryFacet)
    }
    return filters;
  }

  getApplicableFilters() {
    const { holidayGiftFilter, applicableFilters, getItFastSplitFacetToggle } = this.props;
    const giftWrapFilterIndex = applicableFilters
      .findIndex(({ filterKey }) => filterKey === GIFT_WRAP_KEY);
    const inStoreFilterIndex = applicableFilters
      .findIndex(({ filterKey }) => filterKey === IN_STORE);

    return holidayGiftFilter && giftWrapFilterIndex >= 0
      ? this.getUpdatedFilters() : getItFastSplitFacetToggle && inStoreFilterIndex >= 0
      ? this.getUpdatedInStoreFilters(inStoreFilterIndex): applicableFilters;
  }

  getTotalSelectedFilterCount(totalSelectedFilter, filterKey, selectedFiltersList) {
    let selectedFilterKey = filterKey;

    if (filterKey === FAST_DELIVERY || filterKey === FREE_PICKUP) {
    const selectedOmniFilter = selectedFiltersList.filter((item) => item.filterKey === IN_STORE)[0];
      if((selectedOmniFilter?.selectedFilterOptionKey === "sdd" || selectedOmniFilter?.selectedFilterOptionKey === "ndd")
      && filterKey === FAST_DELIVERY) {
        selectedFilterKey = IN_STORE;
      }
      if(selectedOmniFilter?.selectedFilterOptionKey === "csp" && filterKey === FREE_PICKUP){
        selectedFilterKey = IN_STORE;
      }
    }

    return (totalSelectedFilter[selectedFilterKey] !== undefined
      && totalSelectedFilter[selectedFilterKey] !== 0)
    && ` (${totalSelectedFilter[selectedFilterKey]})` || '';
  }

  render() {
    const {
      plpTotalItems,
      srpTotalItems,
      selectedFilterKey,
      totalSelectedFilter,
      selectedFiltersList,
      facetedFiltersList,
      facetedMobileFilterOpened,
      filterDescriptions,
      hybridFacetsCategorySelectorKey,
      hybridFacetsCategorySelector,
      toggleCategorySelector,
      isMobilePhone,
      pageId,
      routeParams,
      navpath,
      source,
      newVisNavToggle,
      holidayGiftFilter,
      holidayGiftIcon,
      facetsMargin,
      brand,
      isGlobalUpdateOn,
      isInStoreFacetAvailable,
      toggleInStoreFacetAvailability
    } = this.props;
    const factedLeftNavClasses = facetedMobileFilterOpened ? 'faceted-left-nav grid-20 tablet-grid-25 ' : 'faceted-left-nav grid-20 tablet-grid-25 hide-on-mobile';
    const {factedNavBottom} = this.state;

    const isFilterOpen = (selectedFilterKey, filterKey, omniFacetOpen) => {
      const filterKeyMatch = selectedFilterKey === filterKey;
      const expandOmniFacet = (filterKey === IN_STORE  || filterKey === FREE_PICKUP || filterKey === FAST_DELIVERY)
        && omniFacetOpen
        && !isMobile();
      return filterKeyMatch || expandOmniFacet;
    };

    const factedNavStyle = (factedNavBottom && factedNavBottom > 1)
      ? {
        bottom: `${factedNavBottom}px`,
        top: 'auto'
      } : {
        overflowY: 'auto',
      };

    const isPageSRP = pageId === 'PAGE_ID_SRP';
    const showClearAll = selectedFiltersList.length > 0 || this.state.srpCategorySelected;
    const filters = this.getApplicableFilters();

    let hasInStoreFacet = false;
    filters && filters.length && filters.forEach(({ filterKey }) => {
      if(filterKey === IN_STORE || filterKey === FREE_PICKUP || filterKey === FAST_DELIVERY) {
        hasInStoreFacet = true;
      }
    });

    if(hasInStoreFacet !== isInStoreFacetAvailable){
      toggleInStoreFacetAvailability(hasInStoreFacet);
    }

    return (
      <>

        <nav
          className={factedLeftNavClasses}
          id="faceted-left-nav"
          style={{ marginTop: isPageSRP || isMobilePhone || newVisNavToggle ? '0px' : '49px' }}
        >
          <div
            style={factedNavStyle}
            className={
            classnames('faceted-left-nav__child grid-100 tablet-grid-100 mobile-grid-100',
              { 'faceted-left-nav__fixed': this.state.isSticky },
              { 'faceted-left-nav__backdrop': isGlobalUpdateOn },
              {'shifted': this.props.facetsMargin})
          }
            id="faceted-left-nav__child"
          >
            <div className="mobile-only mobile-grid-100 faceted-left-nav__mobile-title grid-parent">
              <div className="mobile-grid-60 grid-parent align-left">
                FILTER BY:
              </div>
              <div className="mobile-grid-40 grid-parent align-right">
                <button onClick={this.close}>Done</button>
              </div>
            </div>
            <div className="faceted-left-nav__child__mobile">
              <h3 className="faceted-left-nav__items-count hide-on-tablet hide-on-desktop">
                {renderFormattedNumber(isPageSRP ? srpTotalItems : plpTotalItems)}
                {' Items'}
                { showClearAll
                && (
                  <div className="mobile-only faceted-left-nav__filters__clearall">
                    <button
                      onClick={(e) => this.clearAll(e)}
                    >
                      Clear All Filters
                    </button>
                  </div>
                )
              }
              </h3>
              <div className="faceted-left-nav__filters">
                { this.renderSelectedFiltersList(selectedFiltersList, filterDescriptions) }
              </div>
              <Fragment>
                {
        isPageSRP && this.props.children
      }
                { this.props.showHybridCategoryFacet
                && (
                <div className="faceted-left-nav__filter" key={hybridFacetsCategorySelectorKey}>
                  <div
                    className={
                      classnames('faceted-left-nav__filter__title',
                        {
                          'faceted-left-nav__filter__title__selected': hybridFacetsCategorySelector && selectedFilterKey === '',
                          'border-top': !isMobilePhone,
                        })
                    }
                  >
                    <button
                      key={hybridFacetsCategorySelectorKey}
                      onClick={() => {
                        toggleCategorySelector();
                      }}
                      disabled={false}
                      data-tid={`filter-key-${hybridFacetsCategorySelectorKey}`}
                      aria-expanded={hybridFacetsCategorySelector}
                    >
                      <div className="faceted-left-nav__filter__btn-container">
                        <h3 className={hybridFacetsCategorySelector ? 'faceted-left-nav__facet-selected' : ''}>
                          Category
                        </h3>
                        {this.renderSelectedFilterStatus(
                          hybridFacetsCategorySelector,
                          false,
                          'Category'
                        )}
                      </div>
                    </button>
                  </div>
                  {
                    hybridFacetsCategorySelector
                    && (
                    <ConnectedLeftNav
                      routeParams={{ ...routeParams }}
                      navpath={navpath}
                      source={source}
                    />
                    )
                  }
                </div>
                )
              }
                {
                  filters && filters.length > 0 && filters.map(({
                    displayText, filterKey, facetsOrderList, facetsGroupOrder = false, facets,
                  }, filterIndex) => {
                    /* eslint-disable no-mixed-operators */
                  const isFilterDisabled = (isEmpty(facetsOrderList) &&
                  (this.props.getItFastSplitFacetToggle ? (filterKey !== FAST_DELIVERY && filterKey !== FREE_PICKUP) : filterKey !== IN_STORE));
                    return (
                      <>
                        {filterKey !== 'internationalPrice' && (
                        <div className="faceted-left-nav__filter" key={filterKey}>
                          <div
                            className={classnames(
                              'faceted-left-nav__filter__title',
                              {
                                'faceted-left-nav__filter__title__selected': selectedFilterKey === filterKey || isFilterOpen(selectedFilterKey,
                                  filterKey, this.state.omniFacetOpen) && !isMobilePhone,
                              },
                              { 'faceted-left-nav__filter__title__disabled': isFilterDisabled }
                            )}
                          >
                            <button
                              key={filterKey}
                              onClick={() => this.handleFilterSelection(filterKey, filterIndex)}
                              disabled={isFilterDisabled}
                              data-tid={`filter-key-${filterKey}`}
                              aria-expanded={isFilterOpen(selectedFilterKey, filterKey, this.state.omniFacetOpen) ? 'true' : 'false'}
                            >
                              <div className="faceted-left-nav__filter__btn-container">
                                <h3
                                 className={classnames({
                                    'faceted-left-nav__facet-selected': selectedFilterKey === filterKey || isFilterOpen(selectedFilterKey,
                                    filterKey, this.state.omniFacetOpen) && !isMobilePhone,
                                 })}
                                 key={filterKey}
                                 aria-label={`${this.displayFacetName(displayText)}${this.getTotalSelectedFilterCount(totalSelectedFilter, filterKey, selectedFiltersList)?.trim() ?
                                  `, ${this.getTotalSelectedFilterCount(totalSelectedFilter, filterKey, selectedFiltersList)?.trim()} selected`: ''}`}
                                >
                                  {
                                  holidayGiftFilter && holidayGiftIcon
                                    && filterKey === GIFT_WRAP_KEY
                                    && <img src={GiftIcon} className="gift-icon" alt="Gift Image" />
                                }
                                  {this.displayFacetName(displayText)}
                                  {this.getTotalSelectedFilterCount(totalSelectedFilter, filterKey, selectedFiltersList)}
                                </h3>

                                {
                                  this.renderSelectedFilterStatus(
                                    selectedFilterKey === filterKey || isFilterOpen(
                                      selectedFilterKey,
                                      filterKey,
                                      this.state.omniFacetOpen
                                    ) && !isMobilePhone,
                                    isFilterDisabled,
                                    this.displayFacetName(displayText)
                                  )
                              }

                              </div>
                            </button>
                          </div>
                          {
                          isFilterOpen(
                            selectedFilterKey,
                            filterKey,
                            this.state.omniFacetOpen
                          )
                            && this.renderFacetedFilterList(
                              // ['sdd', 'ndd', 'csp'],
                              facets,
                              filterKey,
                              facetsOrderList,
                              facetsGroupOrder,
                              facetedFiltersList[filterKey === FREE_PICKUP || filterKey === FAST_DELIVERY ? IN_STORE : filterKey],
                              isFilterDisabled,
                              isPageSRP,
                              filterDescriptions
                            )
                          }
                        </div>
                        )}
                      </>
                    );
                  })
              }
              </Fragment>
            </div>
          </div>
          <div className="faceted-left-nav__padding hide-on-tablet hide-on-desktop" onClick={this.close} />
        </nav>
      </>

    );
  }
}

const buildMatch = (designer, matchIndex) => (
  { matchIndex, sortName: designer }
);

const indexOfSearchTerm = (searchTerm = '', name = '') => (
  name && searchTerm ? name.toLowerCase().indexOf(searchTerm.toLowerCase()) : -1
);

const findMatches = curry((searchTerm, designers) => {
  return reduce(designers, (results, designer) => {
    const matchIndex = indexOfSearchTerm(searchTerm, designer);
    return matchIndex >= 0 ? [...results, buildMatch(designer, matchIndex)] : results;
  }, []);
});

const sortMatchesByName = (matches) => (
  map(sortBy(matches, ['matchIndex', 'sortName']), 'sortName')
);

export const FacetedFiltersSelector = createSelector(
  [
    (state) => (state.page.pageId === 'PAGE_ID_PLP'
      ? state.facetedLeftNav.facetedFiltersList
      : state.srp.search.facetedFiltersList),
    (state) => (state.page.pageId === 'PAGE_ID_PLP'
      ? state.productListPage.products.applicableFilters
      : state.srp.search.applicableFilters),
    (state) => state.facetedLeftNav.designerSearchText,
    (state) => state.toggles.SEO_BUCKET_ONE,
    (state) => state.device.isMobilePhone
  ],
  (facetedFiltersList, applicableFilters, designerSearchText, seoToggle, isMobilePhone) => {
    const totalSelectedFilter = {};
    const selectedFiltersList = [];
    let applicableFiltersList = [];
    const filterDescriptions = {};
    let noResultsFound = '';
    const mobileFacetOpen = IS_CLIENT && isMobilePhone ? (document?.getElementById('faceted-left-nav')?.offsetWidth > 0) : false;
    if (facetedFiltersList) {
      Object.entries(facetedFiltersList).forEach(([filterKey, facet]) => {
        let values;
        if (seoToggle && window.location) {
          const { filterOptions } = useFilterOptions(window.location)
          const checkKey = (filterKey === IN_STORE || filterKey === FREE_PICKUP || filterKey === FAST_DELIVERY) ? "get-it-fast" : formatFilterKey(applicableFilters.find(item => item.filterKey === filterKey)?.displayText.toLowerCase())
          values = filterOptions[checkKey]

          if (values) {
            values.forEach(value => {
              !mobileFacetOpen && (facetedFiltersList[filterKey][value] = true);
            })
          }
        }

        let allFacetKeys = Object.keys(facet);

        if (filterKey === IN_STORE || filterKey === FREE_PICKUP || filterKey === FAST_DELIVERY) {
          if (!mobileFacetOpen && selectedFilterCheck !== 'zipChange' && values && values[1] !== '') {
            facet.csp = true
          }
          const validFacetKeys = ['csp', 'ndd', 'sdd'];
          allFacetKeys = allFacetKeys.filter((key) => validFacetKeys.includes(key));
        }

        totalSelectedFilter[filterKey] = allFacetKeys.reduce(
          (previous, key) => {
            const isSelected = facet[key];

            if (filterKey === 'Category') {
              selectedFiltersList.push({
                filterKey,
                selectedFilterOptionKey: facetedFiltersList[filterKey],
              });
            }

            isSelected
                && filterKey !== 'Category'
                && selectedFiltersList.push({
                  filterKey,
                  selectedFilterOptionKey: key,
                });
            const current = isSelected ? 1 : 0;
            return previous + current;
          },
          0
        );


      });
    }

    if (applicableFilters) {
      applicableFiltersList = applicableFilters.map((filterOption) => {
        filterDescriptions[filterOption.filterKey] = filterOption.displayText;

        if (filterOption.filterKey === IN_STORE || filterOption.filterKey === FREE_PICKUP || filterOption.filterKey === FAST_DELIVERY) {
          filterDescriptions[filterOption.filterKey] = 'Get it Fast';
        }
        if (
          filterOption.filterKey === 'Designer'
          && designerSearchText !== ''
        ) {
          const facetsOrderListClone = flow(
            findMatches(designerSearchText),
            sortMatchesByName
          )(filterOption.facetsOrderList);
          if (isEmpty(facetsOrderListClone)) {
            noResultsFound = 'No results found';
          }
          return { ...filterOption, facetsOrderList: facetsOrderListClone };
        }

        return filterOption;
      });
    }

    return {
      totalSelectedFilter,
      selectedFiltersList,
      applicableFiltersList,
      filterDescriptions,
      noResultsFound,
    };
  }
);

const mapStateToProps = (state, ownProps) => {
  const { selectedFilterKey, designerSearchText, facetDeafultExpandList } = state.facetedLeftNav;
  const isDomestic = get(state, 'locale.countryCode') === 'US';
  const facetOrderToggle = get(state.toggles, 'ABTEST_FACET_ORDER_PLP', false);
  const facetOrderAbTest = get(state.abTestsOpt, 'nfo1.variation', 'a');
  const facetOrderplpFacetAvailability = (facetOrderToggle && facetOrderAbTest === 'b');
  let facetedFiltersList = [];
  const pageId = state.page.pageId;

  switch (pageId) {
    case 'PAGE_ID_PLP':
      facetedFiltersList = state.facetedLeftNav.facetedFiltersList;
      break;
    case 'PAGE_ID_SRP':
      facetedFiltersList = state.srp.search.facetedFiltersList;
      break;
    default:
      facetedFiltersList = state.facetedLeftNav.facetedFiltersList;
  }

  const {
    totalSelectedFilter,
    selectedFiltersList,
    applicableFiltersList,
    filterDescriptions,
    noResultsFound,
  } = FacetedFiltersSelector(state);
  const storeIndex = findIndex(applicableFiltersList, { filterKey: IN_STORE }, {});
if(!facetOrderplpFacetAvailability){
  if (storeIndex !== -1) {
    if (!isDomestic) {
      applicableFiltersList.splice(storeIndex, 1)[0];
    } else if (state.toggles.SYSFACET_POSITION) {
      const instoreFltr = applicableFiltersList.splice(storeIndex, 1)[0];
      applicableFiltersList.unshift(instoreFltr);
    } else {
      applicableFiltersList.push(applicableFiltersList.splice(storeIndex, 1)[0]);
    }
  }
}
  const isDefaultFacetExpandOn = state.toggles.FACET_DEFAULT_EXPAND;
  const isMobilePhone = state.device.isMobilePhone;

  if (isDefaultFacetExpandOn && isMobilePhone === false) {
    const [categoryId = ''] = get(ownProps, 'routeParams.categoryId', '').split('_');
    const defaultOpen = findIndex(applicableFiltersList, {
      filterKey: facetDeafultExpandList[categoryId],
    }, {});
    if (defaultOpen !== -1) {
      applicableFiltersList.unshift(applicableFiltersList.splice(defaultOpen, 1)[0]);
    }
  }

  let internationalPriceFacet;
  if (!isDomestic) {
    const internationalPriceIndex = findIndex(applicableFiltersList, { filterKey: 'internationalPrice' }, {});
    if (internationalPriceIndex !== -1) {
      internationalPriceFacet = applicableFiltersList[internationalPriceIndex];
    }
  }

  return {
    pageId,
    isMobilePhone,
    locationInfo: get(state, 'api.requestContext.akamaiEdgescape', {}),
    radius: state.facetedLeftNav.radius,
    address: state.facetedLeftNav.address,
    stores: state.facetedLeftNav.stores,
    facetedFiltersList,
    totalSelectedFilter,
    selectedFiltersList,
    selectedFilterKey,
    designerSearchText,
    filterDescriptions,
    noResultsFound,
    isDefaultFacetExpandOn,
    facetDeafultExpandList,
    showHybridCategoryFacet: !isEmpty(state.leftNavigation),
    hybridFacetsCategorySelector: state.facetedLeftNav.hybridFacetsCategorySelector,
    applicableFilters: applicableFiltersList,
    facetedMobileFilterOpened: state.facetedLeftNav.facetedMobileFilterOpened,
    plpTotalItems: state.productListPage.products.total,
    srpTotalItems: state.srp.search.filteredTotal,
    omniFacetOpen: get(state, 'omniFacet.expanded', {}),
    omniFacetName: get(state, 'omniFacet.facetName', 'Get it Fast'),
    seoFacets: state.facetedLeftNav.seoFacets,
    seoToggle: get(state.toggles, 'SEO_BUCKET_ONE', false),
    newVisNavToggle: get(state.toggles, 'VISUAL_NAVIGATION_NEW', false),
    hpGenderToggle: get(state.toggles, 'HP_GENDER', false),
    holidayGiftFilter: get(state, 'toggles.HOLIDAY_GIFT_FILTER', false),
    holidayGiftIcon: get(state, 'toggles.HOLIDAY_GIFT_ICON', false),
    holidayGiftFilterBtm: get(state, 'toggles.HOLIDAY_GIFT_FILTER_BTM', false),
    internationalPriceFacet,
    isDomestic,
    giftWrapFilterText: get(state, 'giftWrapFilter', {}),
    facetsMargin: get(state, 'facetedLeftNav.facetsMargin', false),
    brand: state.brand_name?.env,
    isGlobalUpdateOn: isGlobalNavUpdateToggleEnabled(state),
    recentlyViewedToggle: get(state.toggles, 'RECENTLY_VIEW_PLP', false),
    recentlyViewedAbTest: get(state.abTestsOpt, 'nmrv0002.variation', 'a') === 'b',
    relatedLinksToggle: get(state.toggles, 'IL_API_PLP', false) && isDomestic,
    relatedProductsToggle: get(state.toggles, 'IL_API_PDP', false) && isDomestic,
    graphiteHQRelatedProducts: get(state, `productCatalog.graphiteHQRelatedProducts`, []),
    session: state.session,
    selectedFavoriteDesigners: get(state, 'facetedLeftNav.selectedFavoriteDesigners', false),
    showFavoriteDesignersCheckBox: get(state.toggles, 'PLP_FAVORITE_DESIGNERS', false) && isDomestic,
    favoriteDesigners: get(state, 'facetedLeftNav.favoriteDesigners', []),
    getItFastSplitFacetToggle: get(state, `abTestsOpt.${ABTEST_GET_IT_FAST}.variation`, 'a') === 'b',
    seoProductRecCarouselAbTest: get(state, 'toggles.ABTEST_PLP_SEO_REC', false) &&
      get(state, `abTestsOpt.${ABTEST_PLP_SEO_REC}.variation`, 'a') === 'b',
    isSysFacetOrderToggleOn: get(state.toggles, 'SYSFACET_ORDER', false),
    relatedFoundItLinksToggle: get(state.toggles, 'FOUNDIT_RELATED_CATEGORIES', false) && isDomestic,
    foundItCategoryLinks: get(state, 'productListPage.foundItRelatedCategories.Links', []),
  };
};

const mapDispatchToProps = ({
  handleFilterSelection,
  handleFilterOptionSelection,
  handleClearAllFilters,
  handleURLUpdate,
  handleSelectFilterByRouteParam,
  handleDesignerFilterChange,
  closeFacetedMobileFilter,
  toggleCategorySelector,
  fetchListOfStoreByAddress,
  getFavoriteDesigners,
  updateSelectedFavoriteDesigner,
});

export default connect(mapStateToProps, mapDispatchToProps)(Facets);
